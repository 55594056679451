define("@ember-data/model/migration-support", ["exports", "@ember/debug", "@ember-data/store", "@ember-data/model/model-YsOraZ6y"], function (_exports, _debug, _store, _modelYsOraZ6y) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerDerivations = registerDerivations;
  _exports.withFields = withFields;
  // 'isDestroying', 'isDestroyed'
  const LegacyFields = ['_createSnapshot', 'adapterError', 'belongsTo', 'changedAttributes', 'constructor', 'currentState', 'deleteRecord', 'destroyRecord', 'dirtyType', 'errors', 'hasDirtyAttributes', 'hasMany', 'isDeleted', 'isEmpty', 'isError', 'isLoaded', 'isLoading', 'isNew', 'isSaving', 'isValid', 'reload', 'rollbackAttributes', 'save', 'serialize', 'unloadRecord'];
  const LegacySupport = new WeakMap();
  function legacySupport(record, options, prop) {
    let state = LegacySupport.get(record);
    if (!state) {
      state = {};
      LegacySupport.set(record, state);
    }
    switch (prop) {
      case '_createSnapshot':
        return _modelYsOraZ6y.j;
      case 'adapterError':
        return record.currentState.adapterError;
      case 'belongsTo':
        return _modelYsOraZ6y.i;
      case 'changedAttributes':
        return _modelYsOraZ6y.g;
      case 'constructor':
        return state._constructor = state._constructor || {
          isModel: true,
          name: "Record<".concat((0, _store.recordIdentifierFor)(record).type, ">"),
          modelName: (0, _store.recordIdentifierFor)(record).type
        };
      case 'currentState':
        return state.recordState = state.recordState || new _modelYsOraZ6y.f(record);
      case 'deleteRecord':
        return _modelYsOraZ6y.e;
      case 'destroyRecord':
        return _modelYsOraZ6y.d;
      case 'dirtyType':
        return record.currentState.dirtyType;
      case 'errors':
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return state.errors = state.errors || _modelYsOraZ6y.E.create({
          __record: record
        });
      case 'hasDirtyAttributes':
        return record.currentState.isDirty;
      case 'hasMany':
        return _modelYsOraZ6y.h;
      case 'isDeleted':
        return record.currentState.isDeleted;
      case 'isEmpty':
        return record.currentState.isEmpty;
      case 'isError':
        return record.currentState.isError;
      case 'isLoaded':
        return record.currentState.isLoaded;
      case 'isLoading':
        return record.currentState.isLoading;
      case 'isNew':
        return record.currentState.isNew;
      case 'isSaving':
        return record.currentState.isSaving;
      case 'isValid':
        return record.currentState.isValid;
      case 'reload':
        return _modelYsOraZ6y.c;
      case 'rollbackAttributes':
        return _modelYsOraZ6y.r;
      case 'save':
        return _modelYsOraZ6y.b;
      case 'serialize':
        return _modelYsOraZ6y.s;
      case 'unloadRecord':
        return _modelYsOraZ6y.u;
      default:
        (false && !(false) && (0, _debug.assert)("".concat(prop, " is not a supported legacy field"), false));
    }
  }
  function withFields(fields) {
    LegacyFields.forEach(field => {
      fields.push({
        type: '@legacy',
        name: field,
        kind: 'derived'
      });
    });
    fields.push({
      name: 'id',
      kind: '@id',
      type: null
    });
    fields.push({
      name: 'isReloading',
      kind: '@local',
      type: 'boolean',
      options: {
        defaultValue: false
      }
    });
    fields.push({
      name: 'isDestroying',
      kind: '@local',
      type: 'boolean',
      options: {
        defaultValue: false
      }
    });
    fields.push({
      name: 'isDestroyed',
      kind: '@local',
      type: 'boolean',
      options: {
        defaultValue: false
      }
    });
    return fields;
  }
  function registerDerivations(schema) {
    schema.registerDerivation('@legacy', legacySupport);
  }
});