define("@ember-data/model/util-3DHZJC9h", ["exports", "@ember/debug", "@ember/string"], function (_exports, _debug, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.c = computedMacroWithOptionalParams;
  _exports.n = normalizeModelName;
  function isElementDescriptor(args) {
    const [maybeTarget, maybeKey, maybeDesc] = args;
    return (
      // Ensure we have the right number of args
      args.length === 3 && (
      // Make sure the target is a class or object (prototype)
      typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) &&
      // Make sure the key is a string
      typeof maybeKey === 'string' && (
      // Make sure the descriptor is the right shape
      typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc ||
      // TS compatibility
      maybeDesc === undefined)
    );
  }
  function computedMacroWithOptionalParams(fn) {
    return function () {
      for (var _len = arguments.length, maybeDesc = new Array(_len), _key = 0; _key < _len; _key++) {
        maybeDesc[_key] = arguments[_key];
      }
      return isElementDescriptor(maybeDesc) ? fn()(...maybeDesc) : fn(...maybeDesc);
    };
  }
  function normalizeModelName(type) {
    {
      const result = (0, _string.dasherize)(type);
      (false && !(result === type) && (0, _debug.deprecate)("The resource type '".concat(type, "' is not normalized. Update your application code to use '").concat(result, "' instead of '").concat(type, "'."), result === type, {
        id: 'ember-data:deprecate-non-strict-types',
        until: '6.0',
        for: 'ember-data',
        since: {
          available: '5.3',
          enabled: '5.3'
        }
      }));
      return result;
    }
    return type;
  }
});