define("@ember-data/request/fetch", ["exports", "@ember-data/request/context-g9X123os"], function (_exports, _contextG9X123os) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function asyncGeneratorStep(n, t, e, r, o, a, c) { try { var i = n[a](c), u = i.value; } catch (n) { return void e(n); } i.done ? t(u) : Promise.resolve(u).then(r, o); }
  function _asyncToGenerator(n) { return function () { var t = this, e = arguments; return new Promise(function (r, o) { var a = n.apply(t, e); function _next(n) { asyncGeneratorStep(a, r, o, _next, _throw, "next", n); } function _throw(n) { asyncGeneratorStep(a, r, o, _next, _throw, "throw", n); } _next(void 0); }); }; }
  /**
   * A basic Fetch Handler which converts a request into a
   * `fetch` call presuming the response to be `json`.
   *
   * ```ts
   * import Fetch from '@ember-data/request/fetch';
   *
   * manager.use([Fetch]);
   * ```
   *
   * @module @ember-data/request/fetch
   * @main @ember-data/request/fetch
   */
  // Lazily close over fetch to avoid breaking Mirage
  const _fetch = typeof fetch !== 'undefined' ? function () {
    return fetch(...arguments);
  } : typeof FastBoot !== 'undefined' ? function () {
    return FastBoot.require('node-fetch')(...arguments);
  } : () => {
    throw new Error('No Fetch Implementation Found');
  };

  // clones a response in a way that should still
  // allow it to stream
  function cloneResponse(response, overrides) {
    const props = (0, _contextG9X123os.c)(response);
    return new Response(response.body, Object.assign(props, overrides));
  }
  let IS_MAYBE_MIRAGE = () => false;
  const MUTATION_OPS = new Set(['updateRecord', 'createRecord', 'deleteRecord']);
  const ERROR_STATUS_CODE_FOR = new Map([[400, 'Bad Request'], [401, 'Unauthorized'], [402, 'Payment Required'], [403, 'Forbidden'], [404, 'Not Found'], [405, 'Method Not Allowed'], [406, 'Not Acceptable'], [407, 'Proxy Authentication Required'], [408, 'Request Timeout'], [409, 'Conflict'], [410, 'Gone'], [411, 'Length Required'], [412, 'Precondition Failed'], [413, 'Payload Too Large'], [414, 'URI Too Long'], [415, 'Unsupported Media Type'], [416, 'Range Not Satisfiable'], [417, 'Expectation Failed'], [419, 'Page Expired'], [420, 'Enhance Your Calm'], [421, 'Misdirected Request'], [422, 'Unprocessable Entity'], [423, 'Locked'], [424, 'Failed Dependency'], [425, 'Too Early'], [426, 'Upgrade Required'], [428, 'Precondition Required'], [429, 'Too Many Requests'], [430, 'Request Header Fields Too Large'], [431, 'Request Header Fields Too Large'], [450, 'Blocked By Windows Parental Controls'], [451, 'Unavailable For Legal Reasons'], [500, 'Internal Server Error'], [501, 'Not Implemented'], [502, 'Bad Gateway'], [503, 'Service Unavailable'], [504, 'Gateway Timeout'], [505, 'HTTP Version Not Supported'], [506, 'Variant Also Negotiates'], [507, 'Insufficient Storage'], [508, 'Loop Detected'], [509, 'Bandwidth Limit Exceeded'], [510, 'Not Extended'], [511, 'Network Authentication Required']]);

  /**
   * A basic handler which converts a request into a
   * `fetch` call presuming the response to be `json`.
   *
   * ```ts
   * import Fetch from '@ember-data/request/fetch';
   *
   * manager.use([Fetch]);
   * ```
   *
   * @class Fetch
   * @public
   */
  const Fetch = _exports.default = {
    request(context) {
      return _asyncToGenerator(function* () {
        let response;
        try {
          response = yield _fetch(context.request.url, context.request);
        } catch (e) {
          if (e instanceof DOMException && e.name === 'AbortError') {
            e.statusText = 'Aborted';
            e.status = 20;
            e.isRequestError = true;
          } else {
            e.statusText = 'Unknown Network Error';
            e.status = 0;
            e.isRequestError = true;
          }
          throw e;
        }
        const isError = !response.ok || response.status >= 400;
        const op = context.request.op;
        const isMutationOp = Boolean(op && MUTATION_OPS.has(op));
        if (!isError && !isMutationOp && response.status !== 204 && !response.headers.has('date')) {
          if (IS_MAYBE_MIRAGE()) {
            response.headers.set('date', new Date().toUTCString());
          } else {
            const headers = new Headers(response.headers);
            headers.set('date', new Date().toUTCString());
            response = cloneResponse(response, {
              headers
            });
          }
        }
        context.setResponse(response);

        // if we are an error, we will want to throw
        if (isError) {
          var _context$request$meth;
          const text = yield response.text();
          let errorPayload;
          try {
            errorPayload = JSON.parse(text);
          } catch (_unused) {
            // void;
          }
          // attempt errors discovery
          const errors = Array.isArray(errorPayload) ? errorPayload : isDict(errorPayload) && Array.isArray(errorPayload.errors) ? errorPayload.errors : null;
          const statusText = response.statusText || ERROR_STATUS_CODE_FOR.get(response.status) || 'Unknown Request Error';
          const msg = "[".concat(response.status, " ").concat(statusText, "] ").concat((_context$request$meth = context.request.method) !== null && _context$request$meth !== void 0 ? _context$request$meth : 'GET', " (").concat(response.type, ") - ").concat(response.url);
          const error = errors ? new AggregateError(errors, msg) : new Error(msg);
          error.status = response.status;
          error.statusText = statusText;
          error.isRequestError = true;
          error.code = error.status;
          error.name = error.statusText.replaceAll(' ', '') + 'Error';
          error.content = errorPayload;
          throw error;
        } else {
          return response.status === 204 ? null : response.json();
        }
      })();
    }
  };
  function isDict(v) {
    return v !== null && typeof v === 'object';
  }
});