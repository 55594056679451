define("@ember-data/model/has-many-PdQBns8a", ["exports", "@ember/debug", "@ember/object", "@ember-data/store", "@ember-data/store/-private", "@ember-data/model/util-3DHZJC9h", "@ember-data/model/model-YsOraZ6y", "@ember/string", "ember-inflector"], function (_exports, _debug, _object, _store, _private, _util3DHZJC9h, _modelYsOraZ6y, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.h = _exports.b = _exports.a = void 0;
  function attr(type, options) {
    if (typeof type === 'object') {
      options = type;
      type = undefined;
    } else {
      options = options || {};
    }
    const meta = {
      type: type,
      kind: 'attribute',
      isAttribute: true,
      options: options,
      key: null
    };
    return (0, _object.computed)({
      get(key) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        return (0, _private.peekCache)(this).getAttr((0, _store.recordIdentifierFor)(this), key);
      },
      set(key, value) {
        (false && !(!this.currentState.isDeleted) && (0, _debug.assert)("Attempted to set '".concat(key, "' on the deleted record ").concat((0, _store.recordIdentifierFor)(this)), !this.currentState.isDeleted));
        const identifier = (0, _store.recordIdentifierFor)(this);
        const cache = (0, _private.peekCache)(this);
        const currentValue = cache.getAttr(identifier, key);
        if (currentValue !== value) {
          cache.setAttr(identifier, key, value);
          if (!this.isValid) {
            const {
              errors
            } = this;
            if (errors.get(key)) {
              errors.remove(key);
              this.currentState.cleanErrorRequests();
            }
          }
        }
        return value;
      }
    }).meta(meta);
  }
  var attr$1 = _exports.a = (0, _util3DHZJC9h.c)(attr);
  function belongsTo(modelName, options) {
    const opts = options;
    const userEnteredModelName = modelName;
    (false && !(opts && typeof opts.async === 'boolean') && (0, _debug.assert)("Expected options.async from @belongsTo('".concat(userEnteredModelName, "', options) to be a boolean"), opts && typeof opts.async === 'boolean'));
    (false && !(opts.inverse === null || typeof opts.inverse === 'string' && opts.inverse.length > 0) && (0, _debug.assert)("Expected options.inverse from @belongsTo('".concat(userEnteredModelName, "', options) to be either null or the string type of the related resource."), opts.inverse === null || typeof opts.inverse === 'string' && opts.inverse.length > 0));
    const meta = {
      type: (0, _util3DHZJC9h.n)(userEnteredModelName),
      isRelationship: true,
      options: opts,
      kind: 'belongsTo',
      name: '<Unknown BelongsTo>',
      key: null
    };
    return (0, _object.computed)({
      get(key) {
        // this is a legacy behavior we may not carry into a new model setup
        // it's better to error on disconnected records so users find errors
        // in their logic.
        if (this.isDestroying || this.isDestroyed) {
          return null;
        }
        const support = (0, _modelYsOraZ6y.l)(this);
        return support.getBelongsTo(key);
      },
      set(key, value) {
        const support = (0, _modelYsOraZ6y.l)(this);
        this.store._join(() => {
          support.setDirtyBelongsTo(key, value);
        });
        return support.getBelongsTo(key);
      }
    }).meta(meta);
  }
  var belongsTo$1 = _exports.b = (0, _util3DHZJC9h.c)(belongsTo);

  /**
    @module @ember-data/model
  */
  function normalizeType(type) {
    {
      const result = (0, _emberInflector.singularize)((0, _string.dasherize)(type));
      (false && !(result === type) && (0, _debug.deprecate)("The resource type '".concat(type, "' is not normalized. Update your application code to use '").concat(result, "' instead of '").concat(type, "'."), result === type, {
        id: 'ember-data:deprecate-non-strict-types',
        until: '6.0',
        for: 'ember-data',
        since: {
          available: '5.3',
          enabled: '5.3'
        }
      }));
      return result;
    }
    return type;
  }

  /**
    `hasMany` is used to define One-To-Many and Many-To-Many
    relationships on a [Model](/ember-data/release/classes/Model).
  
    `hasMany` takes an optional hash as a second parameter, currently
    supported options are:
  
    - `async`: A boolean value used to explicitly declare this to be an async relationship. The default is true.
    - `inverse`: A string used to identify the inverse property on a related model.
    - `polymorphic` A boolean value to mark the relationship as polymorphic
  
    #### One-To-Many
    To declare a one-to-many relationship between two models, use
    `belongsTo` in combination with `hasMany`, like this:
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment') comments;
    }
    ```
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post') post;
    }
    ```
  
    #### Many-To-Many
    To declare a many-to-many relationship between two models, use
    `hasMany`:
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('tag') tags;
    }
    ```
  
    ```app/models/tag.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class TagModel extends Model {
      @hasMany('post') posts;
    }
    ```
  
    You can avoid passing a string as the first parameter. In that case Ember Data
    will infer the type from the singularized key name.
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany tags;
    }
    ```
  
    will lookup for a Tag type.
  
    #### Explicit Inverses
  
    Ember Data will do its best to discover which relationships map to
    one another. In the one-to-many code above, for example, Ember Data
    can figure out that changing the `comments` relationship should update
    the `post` relationship on the inverse because post is the only
    relationship to that model.
  
    However, sometimes you may have multiple `belongsTo`/`hasMany` for the
    same type. You can specify which property on the related model is
    the inverse using `hasMany`'s `inverse` option:
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post') onePost;
      @belongsTo('post') twoPost
      @belongsTo('post') redPost;
      @belongsTo('post') bluePost;
    }
    ```
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment', {
        inverse: 'redPost'
      })
      comments;
    }
    ```
  
    You can also specify an inverse on a `belongsTo`, which works how
    you'd expect.
  
    #### Sync relationships
  
    Ember Data resolves sync relationships with the related resources
    available in its local store, hence it is expected these resources
    to be loaded before or along-side the primary resource.
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment', {
        async: false
      })
      comments;
    }
    ```
  
    In contrast to async relationship, accessing a sync relationship
    will always return a [ManyArray](/ember-data/release/classes/ManyArray) instance
    containing the existing local resources. But it will error on access
    when any of the known related resources have not been loaded.
  
    ```
    post.comments.forEach((comment) => {
  
    });
  
    ```
  
    If you are using `links` with sync relationships, you have to use
    `ref.reload` to fetch the resources.
  
    @method hasMany
    @public
    @static
    @for @ember-data/model
    @param {String} type (optional) type of the relationship
    @param {Object} options (optional) a hash of options
    @return {Ember.computed} relationship
  */
  function hasMany(type, options) {
    (false && !(options && typeof options.async === 'boolean') && (0, _debug.assert)("Expected hasMany options.async to be a boolean", options && typeof options.async === 'boolean')); // Metadata about relationships is stored on the meta of
    // the relationship. This is used for introspection and
    // serialization. Note that `key` is populated lazily
    // the first time the CP is called.
    const meta = {
      type: normalizeType(type),
      options,
      isRelationship: true,
      kind: 'hasMany',
      name: '<Unknown BelongsTo>',
      key: null
    };
    return (0, _object.computed)({
      get(key) {
        if (this.isDestroying || this.isDestroyed) {
          return [];
        }
        return (0, _modelYsOraZ6y.l)(this).getHasMany(key);
      },
      set(key, records) {
        const support = (0, _modelYsOraZ6y.l)(this);
        const manyArray = support.getManyArray(key);
        (false && !(Array.isArray(records)) && (0, _debug.assert)("You must pass an array of records to set a hasMany relationship", Array.isArray(records)));
        this.store._join(() => {
          manyArray.splice(0, manyArray.length, ...records);
        });
        return support.getHasMany(key);
      }
    }).meta(meta);
  }
  var hasMany$1 = _exports.h = (0, _util3DHZJC9h.c)(hasMany);
});